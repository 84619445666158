import { useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { StyleClass } from "primereact/styleclass";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";

interface SidebarNavProps {
  show: boolean;
  handleShow: () => void;
}

export const SidebarNav = ({ show, handleShow }: SidebarNavProps) => {
  const btnRef1 = useRef(null);
  const btnRef2 = useRef(null);
  // const sidebarRef = useRef<Sidebar>(null);
  const { signOut } = useAuthenticator();

  // const handleNavLinkClick = () => {
  //   if (sidebarRef.current) {
  //     sidebarRef.current.hide();
  //   }
  // };

  return (
    <div className="card flex justify-content-center">
      <Sidebar
        visible={show}
        onHide={handleShow}
        position="right"
        content={({ hide }) => (
          <div
            id="app-sidebar-2"
            className="surface-section h-screen flex-shrink-0 left-0 top-0 z-1 border-right-1 surface-border select-none"
          >
            <div className="flex flex-column h-full">
              <div className="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
                <span className="inline-flex align-items-center gap-2">
                  <span className="font-semibold text-2xl text-primary">
                    Menú
                  </span>
                </span>
                <span>
                  <Button
                    type="button"
                    onClick={(e) => hide(e)}
                    icon="pi pi-times"
                    rounded
                    outlined
                    className="h-2rem w-2rem"
                  ></Button>
                </span>
              </div>
              <div className="overflow-y-auto">
                <ul className="list-none p-3 m-0">
                  <li>
                    <ul className="list-none p-0 m-0 overflow-hidden">
                      <li>
                        <StyleClass
                          nodeRef={btnRef2}
                          selector="@next"
                          enterFromClassName="hidden"
                          enterActiveClassName="slidedown"
                          leaveToClassName="hidden"
                          leaveActiveClassName="slideup"
                        >
                          <div
                            ref={btnRef2}
                            className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-calendar mr-2"></i>
                            <span className="font-medium">Eventos</span>
                            <i className="pi pi-chevron-down ml-auto mr-1"></i>
                            <Ripple />
                          </div>
                        </StyleClass>
                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                          <li>
                            <NavLink
                              to="/events"
                              className="no-underline"
                              onClick={handleShow}
                            >
                              <div className="p-ripple flex  align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                <span className="font-medium">
                                  Lista de eventos
                                </span>
                                <Ripple />
                              </div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/events/new"
                              className="no-underline"
                              onClick={handleShow}
                            >
                              <div className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                <span className="font-medium">
                                  Agregar evento
                                </span>
                                <Ripple />
                              </div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/events/types"
                              className="no-underline"
                              onClick={handleShow}
                            >
                              <div className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                <span className="font-medium">
                                  Gestión de sub-tipos
                                </span>
                                <Ripple />
                              </div>
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="list-none p-0 m-0 overflow-hidden">
                      <li>
                        <StyleClass
                          nodeRef={btnRef1}
                          selector="@next"
                          enterFromClassName="hidden"
                          enterActiveClassName="slidedown"
                          leaveToClassName="hidden"
                          leaveActiveClassName="slideup"
                        >
                          <div
                            ref={btnRef1}
                            className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-users mr-2"></i>
                            <span className="font-medium">Usuarios</span>
                            <i className="pi pi-chevron-down ml-auto mr-1"></i>
                            <Ripple />
                          </div>
                        </StyleClass>
                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                          <li>
                            <NavLink
                              to="/users"
                              className="no-underline"
                              onClick={handleShow}
                            >
                              <div className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                <span className="font-medium">
                                  Lista de usuarios
                                </span>
                                <Ripple />
                              </div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/users/new"
                              className="no-underline"
                              onClick={handleShow}
                            >
                              <div className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                <span className="font-medium">
                                  Crear usuario ficticio
                                </span>
                                <Ripple />
                              </div>
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="mt-auto">
                <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                <button
                  type="button"
                  onClick={() => {
                    Cookies.remove("accessToken");
                    Cookies.remove("idToken");
                    signOut();
                  }}
                  className="w-full outline-none border-none bg-white hover:bg-white m-3 flex align-items-center cursor-pointer p-3 gap-2 text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                >
                  <i className="pi pi-sign-out"></i>
                  <span className="font-bold">Cerrar Sesión</span>
                </button>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};
